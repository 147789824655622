.card {
	width: 20.688rem;
	height: 24rem;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}

.card * {
	font-size: 0.7rem;
}

.title {
	display: flex;
	justify-content: space-between;
	color: var(--color);
	margin: 0.1rem 0.3rem;
	border-bottom: 2px solid #e6e6e6;
}

.gestorName {
	display: flex;
	align-items: center;
	gap: 0.4rem;
}

.status {
	background-color: var(--color);
	color: white;
	margin: 0.3rem;
	border-radius: 15px;
	padding: 0.1rem 0.5rem;
	text-wrap: nowrap;
}

.chartsContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding-bottom: 1.2rem;
}

.chartsContainer * {
	flex: 1
}

.chart {
	padding: 0.4rem 0rem;
	height: 6rem;
}

.detailsBox {
	display: flex;
	flex-direction: column;
	background-color: #F5F6F7;
	border-radius: 8px;
	margin: 0.5rem 0.8rem;
	padding-top: 0.5rem;
}

.detailsBox b{
	font-size: 0.9rem;
}

.detailsBox li {
	margin-top: 0.1rem;
	text-align: start;
	font-size: 0.8rem;
}

.mapLink {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0.4rem 0.6rem;
	color: white;
	background-color: #8b8b8b;
	border-radius: 15px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	gap: 0.2rem;
}

.mapLink:hover {
	background-color: #747474;
}