/* Outer-ring */
.chart_gauge circle:nth-child(1) {
  stroke-width: 2;
  stroke: #2d2d2d;
  fill: #2d2d2d;
}

/* Main background */
.chart_gauge circle:nth-child(2) {
  fill: #2d2d2d;
  stroke: #ddd;
  stroke-width: 5;
}

/* Circle of the pointer */
.chart_gauge circle:nth-child(3) {
  fill: #ff8728;
  stroke: #ff8728;
  stroke-width: 1px;
}

/* Main text of the gauge */
.chart_gauge text {
  fill: white;
}

/* Current value text */
.chart_gauge text:nth-child(1) {
  fill: white;
  font-size: 33;
  font-weight: 700;
  font-family: "Orbitron";
}

/* Ticks */
.chart_gauge path {
  stroke: white;
}

/* Circle */
.chart_gauge path:nth-child(2) {
  fill: white;
  stroke: white;
  stroke-width: 1px;
  fill-opacity: 1;
}

/* Warning area */
#chart_div path:nth-child(3) {
  fill: lightgreen;
}

/* Danger area */
#chart_div path:nth-child(4) {
  fill: darkgreen;
}
