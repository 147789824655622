.container {
  background-color: white;
  width: 20rem;
  height: 11.44rem;
  border-radius: 4px;
  border: 1px solid #0000000d;
  position: absolute;
  right: 96px;
  top: 38px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0.875rem;
  justify-content: space-between;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  text-align: start;
  font-size: 0.6875rem;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.selectContainer select {
  width: 18.25rem;
  height: 1.94rem;
  border-radius: 4px;
  border: 1px solid #00000078;
}

.buttonContainer {
  display: flex;
  justify-content: end;
}

.button {
  width: 7.1875rem;
  height: 2rem;
  background-color: #DEDEDE;
  border-radius: 6px;
  color: #000;
  font-size: 0.75rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #cacaca ;
}