.reportContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu {
  display: flex;
  gap: 0.5rem;
  position: relative;
}

.filterButton {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  width: 98px;
  height: 35px;
  flex-shrink: 0;
  cursor: pointer;
}

.generalMenuButton {
  width: 40px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #FFF;
  cursor: pointer;
}

.infoLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  color: #000;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.infoLine>div {
  flex: 1 1 33%;
}

.littleCharts {
  display: flex;
  gap: 2rem;
  height: 19rem;
}

.littleCharts>* {
  border-radius: 9px;
  flex: 1;
}

.pieChartContainer {
  display: flex;
  flex-direction: column;
  text-align: start;
  background-color: #FFF;
  padding: 0.5rem;
  gap: 2rem
}

.pieChartContainer>* {
  display: flex;
  flex-direction: row;
}

.pieChart {
  display: flex;
  flex-direction: row;
  width: 17rem;
}

.pieChart>* {
  width: 18rem;
}

.pieChartLegend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 2px;
}

.pieChartLegend span {
  margin-left: 3px;
  font-size: 1rem;
}

.pieChartLegend b {
  font-size: 1rem;
  margin: 0 0 0 5px;
  white-space: nowrap;
}

.calendarContainer {
  background-color: #FFF;
  display: flex;
  padding: 0.5rem;
}

.calendarInfoContainer {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendarDaysHours {
  display: flex;
  flex-direction: column;
  gap: 0.2rem
}

.calendarDaysHours>* {
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 0.3rem;
}

.calendarDaysHours h3 {
  margin: 0;
}

.calendarCaption {
  font-size: smaller;
  text-align: left;
}

.bigCharts {
  display: flex;
  flex-direction: column;
  gap: 0.5rem
} 

.chart {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  width: 100%;
  height: 27.93rem;
  border-radius: 6px;
  text-align: start;
  padding: 1.2rem 0.8rem;
  gap: 0.5rem;
}

.chart:last-child {
  padding: 0.5rem 0;
  background-color: transparent;
}

.chartLegend {
  display: flex;
  flex-direction: row;
  font-size: small;
  height: auto;
  align-items: center;
}

.tableBody {
  text-align: center;
  gap: 0.2rem
}

.tableBody tr {
  border-bottom: 1px solid #000;
}

.groupedSelect {
  display: flex;
  justify-content: flex-end;
}