.custom-style {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.65);
  --icon-width: 26px;
  --icon-height: 26px;
  --active-height: 50px;
  --close-button-max-size: 12px;
  --icon-button-max-size: 18px;
  --primary-color: #6aad43;
  --secondary-color: #f8f8f8;
  --border-color: rgba(0, 0, 0, 0.2);
  --border-size: 0px;
  --main-background-color: #201e29;
  --background-color-candidate: #5a6673;
  --background-color-hover: #5a6673b3;
  --background-color-active: #50c3bd;
  --svg-stroke-width: 10px;
}

.customSearch {
  position: absolute;
  top: 80px;
  right: 16px;
  z-index: 999;
  background-color: white;
  padding: 6px;
  box-shadow: 0px 10px 30px rgba(0,0,0,0.5);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customSearch .cs__formControl {
  min-width: 220px;
  margin-left: 10px;
}

.customSearch .cs__select {
  text-align: left;
}
