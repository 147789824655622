.container {
	display: flex;
	flex-direction: column;
	gap: 0.2rem;
	max-width: 100%;
	width: 100%;
	position: relative;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 1rem;
}

.headerInfo {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0;
}

.headerTitle {
	font-size: 1rem;
}

.headerDate {
	font-size: 0.75rem;
}

.cardContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.alertsContainer {
	text-align: start;
	margin-top: 1rem;
	font-size: 0.8rem;
	padding: 1rem;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
	background-color: #FFFFFF;
}

.alertsContainer h3 {
	color: red;
	margin: 0;
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	font-size: 10pt;
}

.alertsContainer ul {
	padding: 0;
	margin: 0;
}

.alertsContainer ul li {
	list-style: none;
	margin: 0;
	background-color: rgb(253, 236, 238);
	border-radius: 4px;
	padding: 0.25rem;
	margin-bottom: 0.25rem;
}
